var photoGallery = {
  init: function() {
    var _this = this,
        main = document.querySelector('.js-photoMain');
    if (main !== null) {
      var flkty = new Flickity(main, {
        prevNextButtons: false,
        pageDots: false,
        on: {
          change: function(index) { _this.slideChange(index); }
        }
      });
      this.controlSetting(flkty);
      this.slideChange(0);
    }
  },
  controlSetting: function(flkty) {
    this.$thumb = $('.js-photoThumb .item');
    this.$prev = $('.arrows .prev');
    this.$next = $('.arrows .next');
    this.$current = $('.js-photoPaginate .current');
    
    this.$thumb.on('click', function() { flkty.select($(this).index()); });
    this.$prev.on('click', function() { flkty.previous(false); });
    this.$next.on('click', function() { flkty.next(false); });
  },
  slideChange: function(index) {
    var num = index + 1, thumbLen = this.$thumb.length;
    (thumbLen <= num) ? this.$next.addClass('disabled') : this.$next.removeClass('disabled');
    (1 >= num) ? this.$prev.addClass('disabled') : this.$prev.removeClass('disabled');
    this.$thumb.eq(index).addClass('is-selected').siblings().removeClass('is-selected');
    this.$current.text(num);
  }
};
photoGallery.init();
